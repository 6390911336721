import React, {Component} from 'react'
import {Link} from 'gatsby'

import { Close, ArrowDown } from './icons'
import Logo  from './logo'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      organisation: '',
      email: '',
      phone: '',
      help: '',
      hear: '',
      emailValidation: '',
      nameValidation: ''
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if ( this.state.name === '' || this.state.email === '' ) {
      if ( this.state.name === '' ) {
        this.setState({result: 'fail', nameValidation: 'Please enter your name.' })
      } else {
        this.setState({result: 'fail', nameValidation: '' })
      }
      if ( this.state.email === '' ) {
        this.setState({result: 'fail', emailValidation: 'Please enter an email address.' })
      } else {
        this.setState({result: 'fail', emailValidation: '' })
      }
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => this.setState({result: 'success', msg: 'Thanks for contacting us. One of the team will be in touch shortly.', emailValdiation:'', nameValdiation:''}))
        .catch(error => this.setState({result: 'fail', msg: error}));
    }
  }

  render() {

    return(
      <div className="contact-form">
        <div className='header'>
          <div className='header__inner container'>
            <Link to='/' className='header__logo'>
              <Logo colour='#000' />
            </Link>
            <button onClick={() => document.querySelector('.contact-form').classList.remove('active')}>
              <Close />
            </button>
          </div>
        </div>
        <div className="container">
          { this.state.result !== 'success' &&
            <form 
              className="form" 
              name="contact" 
              method="post" 
              data-netlify="true" 
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="form__row">
                <input type="text" name="name" id="name" placeholder="Name" onChange={this.handleChange} required />
                { this.state.nameValidation !== '' &&
                  <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.nameValidation }} />
                }
              </div>
              <div className="form__row">
                <input type="text" name="organisation" id="organisation" placeholder="Organisation" onChange={this.handleChange} />
              </div>
              <div className="form__row">
                <input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email" />
                { this.state.emailValidation !== '' &&
                  <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.emailValidation }} />
                }
              </div>
              <div className="form__row">
                <input type="text" name="phone" id="phone" placeholder="Phone" onChange={this.handleChange} />
              </div>
              <div className="form__row">
                <input type="text" name="help" id="help" placeholder="How can we help?" onChange={this.handleChange} />
              </div>
              <div className="form__row form__row--select">
                <select name="hear" id="hear" onChange={this.handleChange}>
                  <option value="">How did you hear about us?</option>
                  <option value="friend">Friend</option>
                  <option value="google">Google</option>
                  <option value="other">Other</option>
                </select>
                <ArrowDown />
              </div>
              <div className="form__row form__row--submit">
                <button className="btn" type="submit">Submit</button>
              </div>
            </form>
          }
          { this.state.result === 'success' &&
            <>
              <div className="contact-form__success" dangerouslySetInnerHTML={{__html: 'Thanks! A member of our new business team will be in touch soon'}} />
            </>
          }
        </div>
      </div>
    )

  }

}

export default ContactForm
