import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import TeamCard from './cards/team-card'

const Team = (props) => (
  <StaticQuery
    query={graphql`
      query TeamQuery {
        allWordpressWpTeam(sort: {fields: [menu_order], order: ASC}) {
          edges {
            node {
              title
              slug
              acf {
                profile_picture {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
                position
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <section className="team">
          <Fade bottom distance='40px'>
            <div className="team__inner container">
              <div className="col col--1of3">
                { props.page_title &&
                  <h1>{props.page_title}</h1>
                }
              </div>
              <div className="col col--1of2">
                { props.content &&
                  <div className="team__content" dangerouslySetInnerHTML={{ __html: props.content }} />
                }
              </div>
            </div>
          </Fade>

          <Fade bottom distance='40px'>
            <div className="team__inner container">
              <div className="col col--1of3">
                { props.subtitle &&
                  <h1>{props.subtitle}</h1>
                }
              </div>
              <div className="col col--1of2">
                { props.sub_content &&
                  <div className="team__content" dangerouslySetInnerHTML={{ __html: props.sub_content }} />
                }
              </div>
            </div>
          </Fade>

          <div className="team__items container">
            {
              data.allWordpressWpTeam.edges && data.allWordpressWpTeam.edges.map((el, i) => {
                  let profile_pic = '';
                  //if(el.node.acf.profile_picture.local_file != null)
								try
								{
									profile_pic = el.node.acf.profile_picture.localFile.childImageSharp.original.src;
								}
								catch(ex)
								{
									profile_pic = '';
								}

								let teamProps = {
                  name: el.node.title,
                  slug: el.node.slug,
                  profile_picture: profile_pic,
                  position: el.node.acf.position
                }
                return <TeamCard {...teamProps} key={i} />
              })
            }
          </div>
        </section>
      )
    }}
  />
)

export default Team
