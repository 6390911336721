import React from 'react'

const Awards = props => (
	<section className="title-content title-content--awards">
		<div className="title-content__inner container">
			<div className="col col--1of3">
				{props.title && <h2>{props.title}</h2>}
			</div>
			<div className="col col--2of3">
				<div className="awards">
					<div className="awards__left">
						<div
							className="title-content__content"
							dangerouslySetInnerHTML={{
								__html: props.column_one,
							}}
						/>
					</div>
					<div className="awards__right">
						<div
							className="title-content__content"
							dangerouslySetInnerHTML={{
								__html: props.column_two,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
)

export default Awards
