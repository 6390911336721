import React from 'react'
import Fade from 'react-reveal/Fade'

const Clients = (props) => (
  <section className="clients title-content">
    <Fade bottom distance='40px'>
      <div className="title-content__inner container">
        <div className="col col--1of3">
          { props.title &&
            <h2>{props.title}</h2>
          }
        </div>
        <div className="col col--2of3">
          <div className="clients">
            { props.clients && props.clients.map((el, i) => {
              if (el.logo === null || !el.logo.localFile) return null
              return (
                <a className="clients__logo" href={el.link} key={i}>
                  <img src={el.logo.localFile.childImageSharp.original.src} alt="Ellis Jones - Client" />
                </a>
              )
            }) }
          </div>
        </div>
      </div>
    </Fade>
  </section>
)

export default Clients
