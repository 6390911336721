import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class TeamCard extends Component {
  render() {
    let { profile_picture, name, slug, position } = this.props

    return (
      <Fade bottom distance='40px'>
        <div className="card card--team">
          <Link to={'/team/'+slug}>
            <figure>
              <img src={profile_picture} alt={name} />
            </figure>
            <h4>{name}</h4>
            <span>{position}</span>
          </Link>
        </div>
      </Fade>
    )
  }
}


export default TeamCard
