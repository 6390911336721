import React from 'react'
import ContactForm from './contact-form'
import Fade from 'react-reveal/Fade'

const RequestCall = (props) => (
  <>
    <section className='title-content'>
      <Fade bottom distance='40px'>
        <div className="title-content__inner container">
          <div className="col col--1of3">
            { props.title &&
              <h2>{props.title}</h2>
            }
          </div>
          <div className="col col--2of3">
            { props.content &&
              <div className="title-content__content" dangerouslySetInnerHTML={{ __html: props.content }} />
            }
            <span className="btn btn--link" onClick={() => document.querySelector('.contact-form').classList.add('active')}>Request a call</span>
          </div>
        </div>
      </Fade>
    </section>
    <ContactForm />
  </>
)

export default RequestCall