import React from 'react'
import Fade from 'react-reveal/Fade'

const TitleContent = (props) => (
  <section className={props.colour_theme === 'light' ? 'title-content' : 'title-content title-content--dark'}>
    <Fade bottom distance='40px'>
      <div className="title-content__inner container">
        <div className="col col--1of3">
          { props.title &&
            <h2>{props.title}</h2>
          }
        </div>
        <div className="col col--2of3">
          { props.content &&
            <div className="title-content__content" dangerouslySetInnerHTML={{ __html: props.content }} />
          }
          { props.button_link &&
            <a className="btn btn--link" href={props.button_link}>{props.button_text}</a>
          }
        </div>
      </div>
    </Fade>
  </section>
)

export default TitleContent
