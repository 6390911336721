import React from 'react'
import Fade from 'react-reveal/Fade'

import Map from './map'

const GetInTouch = (props) => (
  <section className="get-in-touch">
    <Fade bottom distance='40px'>
      <div className="get-in-touch__inner container">
        <div className="col col--1of3">
          <h2>{props.title}</h2>
          <p>{props.google_map.address}</p>
          <p>
            <a href={'mailto:'+props.email}>{props.email}</a><br />
            <a href={'tel:'+props.phone}>{props.phone}</a>
          </p>
          <p>
            <a href={props.directions}>Google Map</a><br />
            <a href={props.citymapper}>CityMapper</a>
          </p>
        </div>
        <div className="col col--2of3 get-in-touch__map">
          <figure>
            <img className="studio-img" src={props.image && props.image.localFile && props.image.localFile.childImageSharp.original.src} alt={props.title} />
            <Map {...props.google_map} />
          </figure>
        </div>
      </div>
    </Fade>
  </section>
)

export default GetInTouch
