import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import SlickGallery from '../components/gallery'
import PageCover from '../components/page-cover'
import LatestPosts from '../components/latest-posts'
import GetInTouch from '../components/get-in-touch'
import TitleContent from '../components/title-content'
import RequestCall from '../components/request-call'
import Team from '../components/team'
import Clients from '../components/clients'
import Awards from '../components/awards'
import Subscribe from '../components/subscribe'
import VideoContent from "../components/video-content";

class PageTemplate extends Component {

  renderBlock(param, el, i) {
    let block = {
      'WordPressAcf_gallery': (el, i) => (<SlickGallery {...el} key={i} />),
		'WordPressAcf_video_content': (el, i) => (<VideoContent {...el} key={i} />),
      'WordPressAcf_latest_posts': (el, i) => (<LatestPosts {...el} key={i} />),
      'WordPressAcf_get_in_touch': (el, i) => (<GetInTouch {...el} key={i} />),
      'WordPressAcf_title_content': (el, i) => (<TitleContent {...el} key={i} />),
      'WordPressAcf_request_call': (el, i) => (<RequestCall {...el} key={i} />),
      'WordPressAcf_team': (el, i) => (<Team {...el} key={i} />),
      'WordPressAcf_clients': (el, i) => (<Clients {...el} key={i} />),
      'WordPressAcf_awards': (el, i) => (<Awards {...el} key={i} />),
    }[param]

    if (!block) return

    return block(el, i)
  }

  render() {
    let page = this.props.data.wordpressPage
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} bodyClass={page.slug} description={page.yoast_meta.yoast_wpseo_metadesc} />
        { page.acf.page_cover && <PageCover content={page.acf.page_cover} /> }
        <div className='page-cover__under'>
          { page.acf.content_block_page && page.acf.content_block_page.map((el, i) => {
            return this.renderBlock(el.__typename, el, i)
          }) }
          <Subscribe />
        </div>
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_cover
        content_block_page {
          __typename
          ... on WordPressAcf_gallery {
			overlay_content
				slides {
				    overlay_transparency
		    		overlay_colour
		            type
					image {
						localFile {
						childImageSharp {
							original {
								src
							}
					  	}
					}
			  }
              mobile_image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              video_id
              html
              title
              link
            }
          }
          ... on WordPressAcf_latest_posts {
            title
          }
          ... on WordPressAcf_get_in_touch {
            title
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            google_map {
              address
              lat
              lng
            }
            email
            phone
            directions
            citymapper
          }
          ... on WordPressAcf_video_content {
            text
            placeholder {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            video
            video_title

            image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            paragraph_title
            paragraph
            link_text
            link_path
          }
          ... on WordPressAcf_title_content {
            title
            content
            button_text
            button_link
            colour_theme
          }
          ... on WordPressAcf_request_call {
            title
            content
            confirmation_message
          }
          ... on WordPressAcf_team {
            page_title
            content
            subtitle
            sub_content
          }
          ... on WordPressAcf_clients {
            title
            clients {
              logo {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              link
            }
          }
          ... on WordPressAcf_awards {
            title
            column_one
            column_two
          }
        }
      }
    }
  }
`
